import React from 'react';
import { useUser } from './UserContext';

export const Roles = () => {
  const { roles, loading, error } = useUser();

  if (loading) return <p>Loading roles...</p>;
  if (error) return null;

  return <b>{roles.join(', ')}</b>;
};
