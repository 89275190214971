import { useQuery } from 'react-query';
import { getNotifications } from '../utils/queries';

import {
  styled,
  Container,
  AppBar,
  Box,
  makeStyles,
  Typography,
  Badge,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { Roles } from './Roles';

const useStyles = makeStyles({
  main: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '@media(max-width: 768px)': {
      flexDirection: 'column',
    },
    '& > a': {
      textDecoration: 'none',
    },
  },
  mainTiitle: {
    paddingRight: 18,
  },
  navLinks: {
    display: 'flex',
    gap: 16,
    whiteSpace: 'nowrap',
    flexWrap: 'nowrap',
    alignItems: 'center',
    textDecoration: 'none',

    '& > a': {
      textDecoration: 'none',
      display: 'flex',
      fontWeight: 'normal',
      width: '100%',
    },

    '& > a.active': {
      fontWeight: 'bold',
    },

    '@media(max-width: 768px)': {
      fontSize: '13px',
      marginTop: '1rem',
    },
  },
});

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: -2,
    top: -1,
    border: `1px solid #3f51b5`,
    padding: '0 2px',
    backgroundColor: 'rgba(0,0,0,0.24)',
    color: '#fff',
    fontSize: '10px',
  },
}));
const StyledRoleBadge = styled(StyledBadge)(() => ({
  position: 'absolute',
  top: 16,
  right: 24,
  '& .MuiBadge-badge': {
    position: 'relative',
    top: 0,
    transform: 'none',
    border: `1px solid #3f51b5`,
    padding: '2px 10px',
    backgroundColor: 'rgba(0,0,0,0.24)',
    color: '#fff',
    fontSize: '10px',
  },
}));
export const Header = () => {
  const cls = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: notificationsData = {} } = useQuery(
    'notifications',
    getNotifications
  );

  const {
    new_reviews = 0,
    corrected_free_branches = 0,
    new_offices = 0,
    new_addresses = 0,
  } = notificationsData;

  return (
    <AppBar id="back-to-top-anchor" position="relative">
      <StyledRoleBadge badgeContent={<Roles />}></StyledRoleBadge>

      <Container maxWidth="md">
        <Box className={cls.main}>
          <NavLink
            to="/"
            activeClassName="active"
            className="MuiLink-underlineHover MuiTypography-colorInherit"
          >
            <Typography className={cls.mainTiitle} variant="h5">
              VA
            </Typography>
          </NavLink>
          <div className={cls.navLinks}>
            <NavLink
              to="/reviews"
              activeClassName="active"
              className="MuiLink-underlineHover MuiTypography-colorInherit"
            >
              <StyledBadge badgeContent={new_reviews}>Відгуки</StyledBadge>
            </NavLink>
            <NavLink
              to="/applications"
              activeClassName="active"
              className="MuiLink-underlineHover MuiTypography-colorInherit"
            >
              Заявки
            </NavLink>
            <NavLink
              to="/corrections"
              activeClassName="active"
              className="MuiLink-underlineHover MuiTypography-colorInherit"
            >
              <StyledBadge badgeContent={corrected_free_branches}>
                Правки користувачів
              </StyledBadge>
            </NavLink>
            <NavLink
              to="/offices"
              activeClassName="active"
              className="MuiLink-underlineHover MuiTypography-colorInherit"
            >
              <StyledBadge badgeContent={new_offices}>Офіси</StyledBadge>
            </NavLink>
            <NavLink
              to="/profiles"
              activeClassName="active"
              className="MuiLink-underlineHover MuiTypography-colorInherit"
            >
              Підписки
            </NavLink>
            <NavLink
              to="/branches"
              activeClassName="active"
              className="MuiLink-underlineHover MuiTypography-colorInherit"
            >
              Обмінники
            </NavLink>
            <NavLink
              to="/addresses"
              activeClassName="active"
              className="MuiLink-underlineHover MuiTypography-colorInherit"
            >
              <StyledBadge badgeContent={new_addresses}>Адреси</StyledBadge>
            </NavLink>
            <NavLink
              to="/telephones"
              activeClassName="active"
              className="MuiLink-underlineHover MuiTypography-colorInherit"
            >
              Телефони
            </NavLink>
            <NavLink
              to="/spreads"
              activeClassName="active"
              className="MuiLink-underlineHover MuiTypography-colorInherit"
            >
              Спреди
            </NavLink>
            <NavLink
              to="/photos"
              activeClassName="active"
              className="MuiLink-underlineHover MuiTypography-colorInherit"
            >
              Фото
            </NavLink>
            <NavLink
              to="/developers"
              activeClassName="active"
              className="MuiLink-underlineHover MuiTypography-colorInherit"
            >
              Dev
            </NavLink>

            {/*
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <svg
                style={{ fill: 'white' }}
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-q7mezt"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="MoreVertIcon"
              >
                <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2m0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2"></path>
              </svg>
            </IconButton>
            <Menu
              id="long-menu"
              className={cls.navLinks}
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={handleClose} className={cls.navLinks}>
                <NavLink
                  to="/offices"
                  activeClassName="active"
                  className="MuiLink-underlineHover MuiTypography-colorInherit"
                >
                  Всі офіси
                </NavLink>
              </MenuItem>
            </Menu>
            */}
          </div>
        </Box>
      </Container>
    </AppBar>
  );
};
