import React, { createContext, useContext, ReactNode } from 'react';
import { useQuery } from 'react-query';
import { getPrivateUserInfo } from '../utils/queries';

interface UserContextType {
  roles: string[];
  loading: boolean;
  error: any;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { data, error, isLoading } = useQuery<any>(
    ['PrivateUserInfo'],
    getPrivateUserInfo
  );

  const roles =
    data?.admin_roles ?? (data?.profile_type ? [data.profile_type] : []);

  return (
    <UserContext.Provider value={{ roles, loading: isLoading, error }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
